import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { css, keyframes, styled } from "goober";
import { Button } from "clutch/src/Button/Button.jsx";
import { Card } from "clutch/src/Card/Card.jsx";
import { Select } from "clutch/src/Select/Select.jsx";
import { mobileLarge } from "clutch/src/Style/style.mjs";
import { Tag } from "clutch/src/Tag/Tag.jsx";
import { TextInput } from "clutch/src/TextInput/TextInput.jsx";
import { t } from "i18next";

import { IS_APP } from "@/__main__/constants.mjs";
import { appURLs } from "@/app/app-urls.mjs";
import { getWikiLink } from "@/game-palworld/components/wiki.style.jsx";
import {
  getAllPossibleCombinations,
  getAllPossibleMatesForParent,
  getChild,
  getShortestBreedingPath,
  getUniqueParents,
} from "@/game-palworld/constants/breeding.mjs";
import type { ElementKind } from "@/game-palworld/constants/elements.mjs";
import { ELEMENTS } from "@/game-palworld/constants/elements.mjs";
import { WORK_SUITABILITIES } from "@/game-palworld/constants/work-suitabilities.mjs";
import type { Pal } from "@/game-palworld/models/model-wiki.mjs";
import { formatPaldeckId } from "@/game-palworld/utils/format-paldeck-id.mjs";
import { useWikiData } from "@/game-palworld/utils/use-wiki-data.mjs";
import AddIcon from "@/inline-assets/add.svg";
import ClearIcon from "@/inline-assets/close.svg";
import EqualIcon from "@/inline-assets/equals.svg";
import SearchIcon from "@/inline-assets/search.svg";
import { SquareAvatar } from "@/shared/Avatar.jsx";
import Container from "@/shared/ContentContainer.jsx";
import DataTable from "@/shared/DataTable.jsx";
import FilterBar from "@/shared/FilterBar.jsx";
import { SORT_ASC } from "@/shared/InfiniteTable.jsx";
import PageHeader from "@/shared/PageHeader.jsx";
import { classNames } from "@/util/class-names.mjs";
import { useQuery } from "@/util/router-hooks.mjs";

const MAX_RESULTS = 1000;

// type OwnedPal = Pal & { amount: number };

const StyledFilterBar = styled(FilterBar)`
  display: flex;
  justify-content: flex-end;
  margin-bottom: var(--sp-2);
`;

const cssBreedingBar = () => css`
  & {
    display: grid;
    grid-template-columns: 1fr 1fr var(--sp-6) 1fr;
    grid-template-rows: var(--sp-48);
    align-items: center;
    gap: var(--sp-4);
  }

  .equals {
    position: relative;
    align-self: flex-start;
    color: var(--shade2-50);

    svg {
      position: absolute;
      top: calc(var(--sp-20) - var(--sp-3));
      width: var(--sp-6);
      height: var(--sp-6);
    }

    ${mobileLarge} {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;

      svg {
        top: calc(var(--sp-12) - var(--sp-3));
      }
    }
  }

  ${mobileLarge} {
    & {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: repeat(2, var(--sp-32));
    }
  }
`;

const cssPalCard = () => css`
  & {
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;
    width: 100%;
    cursor: pointer;
    animation: ${keyframes`
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    `} 0.2s ease-in-out;

    &:after {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: var(--sp-1);
      width: 100%;
      transform: translateY(100%);
      z-index: 1;
    }
  }

  .search-input {
    --border-height: 1px;
    position: absolute;
    bottom: 0;
    width: 100%;

    .clear {
      position: absolute;
      right: var(--sp-3);
      top: 50%;
      transform: translateY(-50%);
      width: var(--sp-4);
      height: var(--sp-4);
      cursor: pointer;
      border-radius: 50%;
      background-color: var(--shade2);
      color: var(--shade10);
      z-index: 2;

      svg {
        width: 100%;
        height: 100%;
      }
    }

    & > label {
      border: none !important;
      outline: none !important;
      border-radius: 0;

      ${mobileLarge} {
        & > input {
          font-size: var(--sp-4);
        }
      }
    }

    &::after {
      content: "";
      display: block;
      height: var(--border-height);
      background: linear-gradient(
        to right,
        var(--element-primary) 33%,
        var(--element-secondary) 66%
      );
      transform: translateY(-100%);
      position: absolute;

      top: 0;
      left: 0;
      right: 0;
    }
  }

  .search-results {
    position: absolute;
    pointer-events: none;
    opacity: 0;
    top: calc(100% + var(--sp-1));
    left: 0;
    width: 100%;
    min-width: 200px;
    background-color: var(--shade10);
    drop-shadow: 0 0 4px var(--shade3-50);
    border-radius: var(--br-lg);
    overflow-y: auto;
    max-height: calc(var(--sp-1) * 75);
    z-index: 10;
    transition: opacity 0.2s ease-in-out 0.1s;

    &.focused {
      opacity: 1;
    }

    .result-row {
      padding: var(--sp-2);
      display: flex;
      cursor: pointer;
      align-items: center;

      .avatar {
        background-color: var(--shade3-15);
        border: 1px solid var(--shade3-15);
      }

      &:hover {
        background-color: var(--shade5-50);
      }
    }
  }

  .pal-img {
    --bg: var(--shade8);
    position: relative;
    height: var(--sp-48);
    border-radius: var(--br-lg);
    background-color: var(--bg);

    overflow: clip;

    border: 1px solid var(--shade3-15);

    img {
      width: 100%;
      height: calc(100% - var(--sp-10));
      object-fit: contain;
    }

    .add-icon {
      position: absolute;
      top: calc(var(--sp-20) - var(--sp-3));
      left: calc(50% - var(--sp-3));
      width: var(--sp-6);
      height: var(--sp-6);

      svg {
        width: 100%;
        height: 100%;
        color: var(--shade2-50);
      }

      ${mobileLarge} {
        top: calc(var(--sp-12) - var(--sp-3));
      }
    }

    &:hover,
    &:focus {
      outline: 2px solid var(--shade4);
      outline-offset: 2px;

      .add-icon {
        svg {
          color: var(--shade1);
        }
      }
    }
  }

  ${mobileLarge} {
    .search-results {
      left: calc(var(--sp-2) * -1);
      width: 100vw;
      max-height: var(--sp-48);
    }

    &:nth-child(1n + 2) {
      .search-results {
        left: calc(var(--sp-2) * -1);
        transform: translateX(-50%);
      }
    }
  }
`;

const cssPalsTable = () => css`
  .table-container {
    overflow: hidden;
  }
  .avatar {
    background-color: var(--shade3-15);
    border: 1px solid var(--shade3-15);
  }
  .parent-label-wrapper {
    display: flex;
    align-items: center;
    gap: var(--sp-1);
  }
  .parent-label {
    font-weight: 600;
    color: var(--shade0);

    &:hover {
      text-decoration: underline;
      color: var(--cyan-50);
    }
  }
  .parent-plus {
    font-weight: 400;
    color: var(--shade2-50);
  }
  .table-container tr {
    cursor: pointer;
    &:hover {
      background-color: var(--shade5-50);
    }
  }
  .work-skills {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    col-gap: var(--sp-0_5);
    row-gap: 0;
    align-items: center;
    justify-items: center;

    .work-skill {
      position: relative;
      display: flex;
      align-items: center;
      img {
        width: var(--sp-5);
        height: var(--sp-5);
      }
      sub {
        position: absolute;
        font-size: 0.5rem;
        bottom: -0.5rem;
        right: -0.1rem;
      }
    }
  }
`;

const cssPageHeader = () => css`
  & {
    padding-block: var(--sp-1) var(--sp-5);
    border-bottom: 1px solid var(--shade6-50);
  }

  ${mobileLarge} {
    .inner-container {
      flex-direction: column;
    }
  }
`;

type ParnerSkillByCategory = Record<string, Pal["partnerSkill"][]>;
const createRow = ({
  onClick,
  left,
  right,
  child,
  filters,
  allPartnerSkills,
}: {
  onClick: () => void;
  left: Pal;
  right: Pal;
  child: Pal;
  filters: Record<string, string>;
  allPartnerSkills: ParnerSkillByCategory;
}) => {
  // if child is filtered, retun null
  if (
    filters.element &&
    !child.elements.includes(filters.element as ElementKind)
  ) {
    return null;
  }
  if (
    filters.workSkill &&
    !Object.entries(child.workSutiability).some(
      ([workSkill, num]) => workSkill === filters.workSkill && num > 0,
    )
  ) {
    return null;
  }
  if (
    filters.partnerSkill &&
    !allPartnerSkills[filters.partnerSkill].some(
      (partnerSkill) => partnerSkill.imageUri === child.partnerSkill.imageUri,
    )
  ) {
    return null;
  }

  const element1 = ELEMENTS[child.elements[0]];
  const element2 = ELEMENTS[child.elements[1]];

  return [
    {
      value: t(...left.label) + t(...right.label),
      onClick,
      display: (
        <>
          <SquareAvatar
            avatar={`${appURLs.CDN_PLAIN}/${left.imageUri}`}
            title={""}
          />
          <SquareAvatar
            avatar={`${appURLs.CDN_PLAIN}/${right.imageUri}`}
            title={
              <div className="parent-label-wrapper">
                <a
                  onClick={(e) => e.stopPropagation()}
                  href={getWikiLink(`pal:${left.id}`)}
                  className="parent-label"
                >
                  {t(...left.label)}
                </a>
                <span className="parent-plus">+</span>
                <a
                  onClick={(e) => e.stopPropagation()}
                  href={getWikiLink(`pal:${right.id}`)}
                  className="parent-label"
                >
                  {t(...right.label)}
                </a>
              </div>
            }
          />
        </>
      ),
    },
    {
      value: t(...child.label),
      onClick,
      display: (
        <SquareAvatar
          avatar={`${appURLs.CDN_PLAIN}/${child.imageUri}`}
          title={
            <a
              onClick={(e) => e.stopPropagation()}
              href={getWikiLink(`pal:${child.id}`)}
              className="parent-label"
            >
              {t(...child.label)}
            </a>
          }
        />
      ),
    },
    {
      value: formatPaldeckId(child.paldeckId, child.paldeckIdSuffix || "A"),
      onClick,
      display: formatPaldeckId(child.paldeckId, child.paldeckIdSuffix),
    },
    {
      value: String(child.rarity),
      onClick,
      display: <div className="rarity">{child.rarity}</div>,
    },
    {
      value: t(...element1.label) + (element2 ? t(...element2.label) : ""),
      onClick,
      display: (
        <div className="flex gap-1">
          <Tag color={element1.color} text={t(...element1.label)} />
          {element2 && (
            <Tag color={element2.color} text={t(...element2.label)} />
          )}
        </div>
      ),
    },
    {
      value: String(
        Object.entries(child.workSutiability)
          .filter(
            ([key, _]) =>
              filters.workSkill === "" || String(key) === filters.workSkill,
          )
          .sort((a, b) => b[1] - a[1])
          .slice(0, filters.workSkill ? 1 : 2)
          .reduce((prev, curr) => prev + curr[1], 0) ?? 0,
      ),
      onClick,
      display: (
        <div className="work-skills">
          {Object.entries(child.workSutiability)
            .filter(([_, num]) => num > 0)
            .sort((a, b) => b[1] - a[1])
            .map(([workSkill, num]) => (
              <div className="work-skill" key={workSkill}>
                <img
                  src={WORK_SUITABILITIES[workSkill]?.src}
                  alt={t(...(WORK_SUITABILITIES[workSkill]?.label || []))}
                  title={t(...(WORK_SUITABILITIES[workSkill]?.label || []))}
                />
                <sub>{num}</sub>
              </div>
            ))}
        </div>
      ),
    },
    {
      value: child.partnerSkill.imageUri,
      onClick,
      display: (
        <div
          className="flex gap-1 text-callout align-center"
          data-tooltip={t(...child.partnerSkill.name)}
          title={t(...child.partnerSkill.description)}
        >
          <SquareAvatar
            linkDesc="partner skill"
            avatar={`${appURLs.CDN_PLAIN}/${child.partnerSkill.imageUri}`}
            title={""}
          />
        </div>
      ),
    },
  ];
};

const PalworldBreedingCalculatorImpl = ({
  title,
  pals,
}: {
  title: string;
  pals: Record<string, Pal>;
}) => {
  const [allPals, allPartnerSkills] = useMemo(() => {
    const allPals: Pal[] = Object.values(pals) as Pal[];
    const allPartnerSkills = allPals.reduce<
      Record<string, Pal["partnerSkill"][]>
    >((acc, pal) => {
      if (pal.partnerSkill) {
        const map = (acc[pal.partnerSkill.imageUri] ||= []);
        map.push(pal.partnerSkill);
      }
      return acc;
    }, {});
    return [allPals, allPartnerSkills];
  }, [pals]);

  // const [palStorage, setPalStorage] = useState<null | OwnedPal[]>([]);
  const [clearQuery, setClearQuery] = useQuery<null | string>("clear", null);
  const [leftParent, setLeftParent] = useQuery<null | string>("parent1", null);
  const [rightParent, setRightParent] = useQuery<null | string>(
    "parent2",
    null,
  );
  const [child, setChild] = useQuery<null | string>("child", null);
  const [leftParents, setLeftParents] = useState<Pal[]>(allPals);
  const [rightParents, setRightParents] = useState<Pal[]>(allPals);
  const [children, setChildren] = useState<Pal[]>(allPals);
  const [resultCount, setResultCount] = useState(0);

  // filters
  const [elementFilter, setElementFilter] = useState("");
  const elementOptions = [
    {
      value: "",
      text: ["palworld:filters.allElements", "All Elements"] as Translation,
    },
    ...Object.entries(ELEMENTS).map(([key, value]) => ({
      value: key,
      text: value.label,
      image: value.imageSrc,
    })),
  ];

  const [workSkillFilter, setWorkSkillFilter] = useState("");
  const workSkillOptions = [
    {
      value: "",
      text: [
        "palworld:filters.allWorkSkills",
        "All Work Skills",
      ] as Translation,
    },
    ...Object.entries(WORK_SUITABILITIES).map(([key, value]) => ({
      value: key,
      text: value.label,
      image: value.src,
    })),
  ];

  const [partnerSkillFilter, setPartnerSkillFilter] = useState("");
  const partnerSkillOptions = [
    {
      value: "",
      text: [
        "palworld:filters.allPartnerSkills",
        "All Partner Skills",
      ] as Translation,
    },
    ...Object.entries(allPartnerSkills)
      .map(([key, value]) => ({
        value: key,
        text: [
          "palworld:partnerSkillsVariants",
          "{{count}} variants",
          { count: value.length },
        ] as Translation,
        image: `${appURLs.CDN_PLAIN}/${key}`,
      }))
      .sort((a, b) => a.text[1].localeCompare(b.text[1]))
      .sort((a, b) => a.image.localeCompare(b.image)),
  ].sort((a, b) => Number(b.text[2]?.["count"]) - Number(a.text[2]?.["count"]));

  const filters = useMemo(() => {
    return {
      element: elementFilter,
      workSkill: workSkillFilter,
      partnerSkill: partnerSkillFilter,
    };
  }, [elementFilter, workSkillFilter, partnerSkillFilter]);

  const clearAllFilters = useCallback(() => {
    setElementFilter("");
    setWorkSkillFilter("");
    setPartnerSkillFilter("");
  }, [setElementFilter, setWorkSkillFilter, setPartnerSkillFilter]);

  // useEffect(() => {
  //   // console.log("load data", EVENTS.PALWORLD_REQUEST_DATA);
  //   setTimeout(() => {
  //     blitzMessage(EVENTS.PALWORLD_REQUEST_DATA, { name: "pal_storage" }).then(
  //       () => {
  //         // console.log(data);
  //       },
  //     );
  //     // .catch((err) => console.log(err));
  //   }, 1000);
  // }, []);

  const handleSelectLeftParent: React.MouseEventHandler = (e) => {
    const palId = (e.target as HTMLLIElement)?.closest<HTMLLIElement>(
      ".result-row",
    )?.dataset?.id;
    const selectedParent = allPals.find((pal) => pal.id === palId);
    setLeftParent(selectedParent.id || null);
  };

  const handleSelectRightParent: React.MouseEventHandler = (e) => {
    const palId = (e.target as HTMLLIElement)?.closest<HTMLLIElement>(
      ".result-row",
    )?.dataset?.id;
    const selectedParent = allPals.find((pal) => pal.id === palId);
    if (!leftParent) {
      setLeftParent(selectedParent.id || null);
      return;
    }
    setRightParent(selectedParent.id || null);
  };

  const handleSelectChild: React.MouseEventHandler = (e) => {
    const palId = (e.target as HTMLLIElement)?.closest<HTMLLIElement>(
      ".result-row",
    )?.dataset?.id;
    const selectedChild = allPals.find((pal) => pal.id === palId);
    setChild(selectedChild.id || null);
  };

  const handleClearParent1 = useCallback(() => {
    if (rightParent) {
      setLeftParent(rightParent);
      setRightParent(null);
    } else {
      setLeftParent(null);
    }
  }, [rightParent, setLeftParent, setRightParent]);

  const handleClearParent2 = useCallback(() => {
    setRightParent(null);
  }, [setRightParent]);

  const handleClearChild = useCallback(() => {
    setChild(null);
  }, [setChild]);

  const handleClearAll = () => {
    setLeftParent(null);
    setRightParent(null);
    setChild(null);
    clearAllFilters();
  };

  useEffect(() => {
    if (clearQuery) {
      handleClearAll();
      setClearQuery(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clearQuery, setClearQuery]);

  const rows = useMemo(() => {
    setLeftParents(allPals);
    setRightParents(allPals);
    setRightParents(allPals);
    setChildren(allPals);

    if (!child) {
      if (leftParent && !rightParent) {
        const mates = getAllPossibleMatesForParent(leftParent).sort(
          (a, b) => a.paldeckId - b.paldeckId,
        );
        const allPossibleChildren = mates.map((mate) =>
          getChild(leftParent, mate.id),
        );
        const allUniqueChildren = Array.from(
          new Map(
            allPossibleChildren.map((child) => [child.id, child]),
          ).values(),
        );
        setChildren(allUniqueChildren);
        setResultCount(mates.length);
        setRightParents(mates);
        setRightParents(mates);

        return mates.map((mate) => {
          const child = getChild(leftParent, mate.id);
          const handleClickRow = () => {
            setRightParent(mate.id);
            setChild(child.id);
          };
          return createRow({
            onClick: handleClickRow,
            left: pals[leftParent],
            right: mate,
            child,
            filters,
            allPartnerSkills,
          });
        });
      } else if (!leftParent && rightParent) {
        const mates = getAllPossibleMatesForParent(rightParent).sort(
          (a, b) => a.paldeckId - b.paldeckId,
        );
        const allPossibleChildren = mates.map((mate) =>
          getChild(mate.id, rightParent),
        );
        const allUniqueChildren = Array.from(
          new Map(
            allPossibleChildren.map((child) => [child.id, child]),
          ).values(),
        );
        setChildren(allUniqueChildren);
        setResultCount(mates.length);
        setLeftParents(mates);

        return mates.map((mate) => {
          const child = getChild(mate.id, rightParent);
          const handleClickRow = () => {
            setLeftParent(mate.id);
            setChild(child.id);
          };
          return createRow({
            onClick: handleClickRow,
            left: mate,
            right: pals[rightParent],
            child,
            filters,
            allPartnerSkills,
          });
        });
      } else if (leftParent && rightParent) {
        const child = getChild(leftParent, rightParent);
        setChildren([child]);
        setResultCount(1);

        const handleClickRow = () => {
          setChild(child.id);
        };

        return [
          createRow({
            onClick: handleClickRow,
            left: pals[leftParent],
            right: pals[rightParent],
            child,
            filters,
            allPartnerSkills,
          }),
        ];
      } else if (!leftParent && !rightParent) {
        const allCombinations = getAllPossibleCombinations(
          MAX_RESULTS,
          filters,
        );
        const results = allCombinations.map(([parent1, parent2, child]) => {
          const handleClickRow = () => {
            setLeftParent(parent1.id);
            setRightParent(parent2.id);
            setChild(child.id);
          };
          return createRow({
            onClick: handleClickRow,
            left: parent1,
            right: parent2,
            child,
            filters,
            allPartnerSkills,
          });
        });
        setResultCount(results.length);
        return results;
      }
    }
    if (leftParent) {
      const mates = (
        getShortestBreedingPath(child, [pals[leftParent]])[0]?.possibleMates ??
        []
      ).sort((a, b) => a.paldeckId - b.paldeckId);
      setResultCount(mates.length);
      setRightParents(mates);
      setRightParents(mates);
      return mates.map((mate) => {
        const child = getChild(leftParent, mate.id);
        const handleClickRow = () => {
          setRightParent(mate.id);
          setChild(child.id);
        };
        return createRow({
          onClick: handleClickRow,
          left: pals[leftParent],
          right: mate,
          child,
          filters,
          allPartnerSkills,
        });
      });
    } else if (!leftParent && rightParent) {
      const mates = (
        getShortestBreedingPath(child, [pals[rightParent]])[0]?.possibleMates ??
        []
      ).sort((a, b) => a.paldeckId - b.paldeckId);
      setResultCount(mates.length);
      return mates.map((mate) => {
        const child = getChild(mate.id, rightParent);
        const handleClickRow = () => {
          setLeftParent(mate.id);
          setChild(child.id);
        };
        return createRow({
          onClick: handleClickRow,
          left: mate,
          right: pals[rightParent],
          child,
          filters,
          allPartnerSkills,
        });
      });
    } else if (!leftParent && !rightParent) {
      const uniqueParents = getUniqueParents(child);
      const uniqueCombinations = new Map<string, [Pal, Pal]>();
      uniqueParents.forEach((parent) => {
        const mates = getShortestBreedingPath(child, [parent])[0].possibleMates;
        mates.forEach((mate) => {
          const key =
            parent.id > mate.id
              ? `${mate.id}-${parent.id}`
              : `${parent.id}-${mate.id}`;
          uniqueCombinations.set(key, [parent, mate]);
        });
      });

      const results = Array.from(uniqueCombinations)
        .sort((a, b) => a[1][1].paldeckId - b[1][1].paldeckId)
        .sort((a, b) => {
          return a[1][0].paldeckId - b[1][0].paldeckId;
        });
      setResultCount(results.length);
      return results.map(([_, [parent, mate]]) => {
        const handleClickRow = () => {
          setLeftParent(parent.id);
          setRightParent(mate.id);
          setChild(child);
        };
        return createRow({
          onClick: handleClickRow,
          left: parent,
          right: mate,
          child: pals[child],
          filters,
          allPartnerSkills,
        });
      });
    }
    setResultCount(0);
    return [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [child, leftParent, rightParent, filters]);

  return (
    <Container className="flex column gap-sp-4">
      <PageHeader
        title={title}
        className={cssPageHeader()}
        aside={
          <Button onClick={handleClearAll} emphasis="medium">
            {t("common:clearAll", "Clear All")}
          </Button>
        }
      />
      <div className="flex top-bar gap-sp-2 column">
        <section className={cssBreedingBar()}>
          <PalCard
            pal={pals[leftParent]}
            results={leftParents}
            placeholder={[
              "palworld:breedingCalculator.addParent",
              "Add Parent",
            ]}
            handleClick={handleSelectLeftParent}
            handleClear={handleClearParent1}
          />
          <PalCard
            pal={pals[rightParent]}
            results={rightParents}
            placeholder={[
              "palworld:breedingCalculator.addParent",
              "Add Parent",
            ]}
            handleClick={handleSelectRightParent}
            handleClear={handleClearParent2}
          />
          <div className="equals">
            <EqualIcon />
          </div>
          <PalCard
            pal={pals[child]}
            results={children}
            placeholder={["palworld:breedingCalculator.addChild", "Add Child"]}
            handleClick={handleSelectChild}
            handleClear={handleClearChild}
          />
        </section>
      </div>
      <h3 className="result-count">
        {!leftParent &&
        !rightParent &&
        !child &&
        !elementFilter &&
        !workSkillFilter &&
        !partnerSkillFilter
          ? t(
              "palworld:breeding.resultsAll",
              "Showing {{results}} of 18,750 combinations",
              { results: Intl.NumberFormat().format(resultCount) },
            )
          : resultCount === MAX_RESULTS
            ? t(
                "palworld:breeding.resultsLimitedFilters",
                "Showing only the first {{results}} combinations. Try different filters",
                { results: Intl.NumberFormat().format(MAX_RESULTS) },
              )
            : t("palworld:breeding.results", "Found {{results}} combinations", {
                results: Intl.NumberFormat().format(resultCount),
              })}
      </h3>
      <section className={cssPalsTable()}>
        <StyledFilterBar>
          <Select
            selected={elementFilter}
            options={elementOptions}
            onChange={(v) => setElementFilter(v)}
          />
          <Select
            selected={workSkillFilter}
            options={workSkillOptions}
            onChange={(v) => setWorkSkillFilter(v)}
          />
          <Select
            selected={partnerSkillFilter}
            options={partnerSkillOptions}
            onChange={(v) => setPartnerSkillFilter(v)}
          />
        </StyledFilterBar>
        <DataTable
          sortable
          sortCol={2}
          sortDir={SORT_ASC}
          noResultsTitle={[
            "palworld:breedingCalculator.noResults",
            "Sorry, no results found.",
          ]}
          noResultsDescription={[
            "palworld:breedingCalculator.noResultsSubtitle",
            "Try different parents, child, or filters",
          ]}
          noResultsReturnLink="/palworld/breeding-calculator?clear=true"
          noResultsReturnText={[
            "palworld:breedingCalculator.noResultsReturn",
            "Clear All and Try Again",
          ]}
          cols={[
            {
              display: t("palworld:breeding.parents", "Parents"),
              align: "left",
            },
            {
              display: t("palworld:breeding.child", "Child"),
              align: "left",
            },
            {
              display: t("palworld:breeding.paldeckId", "Pal ID"),
              align: "right",
            },
            {
              display: t("palworld:breeding.rarity", "Rarity"),
              isStat: true,
              align: "right",
            },
            {
              display: t("palworld:breeding.elements", "Elements"),
              align: "left",
            },
            {
              display: t("palworld:breeding.workSkills", "Work Skills"),
              align: "left",
            },
            {
              display: t("palworld:breeding.partnerSkill", "Partner Skill"),
            },
          ]}
          rows={rows}
        />
      </section>
    </Container>
  );
};

const PalCard = ({
  pal,
  results,
  placeholder,
  handleClick,
  handleClear,
}: {
  pal: Pal;
  results: Pal[];
  placeholder: Translation;
  handleClick: React.MouseEventHandler;
  handleClear: () => void;
}) => {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const resultsRef = React.useRef<HTMLUListElement>(null);
  const [search, setSearch] = useState(pal ? t(...pal.label) : "");
  const [focused, setFocused] = useState(false);

  const handleFocus = () => {
    setFocused(true);
    inputRef.current?.focus();
    resultsRef.current?.style.setProperty("pointer-events", "auto");
  };

  const handleBlur = useCallback(() => {
    setFocused(false);
    resultsRef.current?.style.setProperty("pointer-events", "none");
  }, []);

  const handleSelect: React.MouseEventHandler = (e) => {
    handleClick(e);
    setSearch("");
    // eslint-disable-next-line
    inputRef.current?.value && (inputRef.current.value = "");
    handleBlur();
  };

  const clear: React.MouseEventHandler = useCallback(
    (e) => {
      e?.stopPropagation?.();
      setSearch("");
      // eslint-disable-next-line
      inputRef.current?.value && (inputRef.current.value = "");
      handleClear();
      handleBlur();
    },
    [handleBlur, handleClear],
  );

  useEffect(() => {
    if (!pal && !focused && !search) {
      clear({} as React.MouseEvent);
    }
  }, [clear, focused, pal, search]);

  const filteredResults = useMemo(
    () =>
      results
        .filter((result) =>
          String(
            formatPaldeckId(result.paldeckId, result.paldeckIdSuffix) +
              " " +
              t(...result.label),
          )
            .toLowerCase()
            .includes(search.toLowerCase()),
        )
        .sort((a, b) => a.paldeckId - b.paldeckId),
    [results, search],
  );

  return (
    <div
      className={`${cssPalCard()} flex gap-sp-3`}
      onMouseLeave={handleBlur}
      style={{
        "--element-primary":
          ELEMENTS[pal?.elements[0]]?.color || "var(--shade3-15)",
        "--element-secondary":
          ELEMENTS[pal?.elements[1] || pal?.elements[0]]?.color ||
          "var(--shade3-15)",
      }}
    >
      <div
        className="pal-img"
        onClick={handleFocus}
        style={{ "--bg": pal ? "var(--shade6)" : "var(--shade8)" }}
      >
        {pal ? (
          <img
            src={pal && `${appURLs.CDN_PLAIN}/${pal.imageUri}`}
            alt={t(
              ...(pal?.label || [
                "palworld:breedingCalculator.empty",
                "No Pal Selected",
              ]),
            )}
          />
        ) : (
          <div className="add-icon">
            <AddIcon />
          </div>
        )}
        <div
          className="search-input"
          style={{
            "--border-color": pal
              ? ELEMENTS[pal.elements[0]]?.color
              : "var(--shade3-15)",
            "--border-height": pal ? "var(--sp-1_5)" : "1px",
          }}
        >
          <TextInput
            ref={inputRef}
            Icon={SearchIcon}
            placeholder={t(...(pal?.label || placeholder))}
            onValueChange={(value) => setSearch(value)}
            onFocus={handleFocus}
          />
          {Boolean(pal || search) && (
            <div className="clear" onClick={clear}>
              <ClearIcon />
            </div>
          )}
        </div>
      </div>
      <ul
        ref={resultsRef}
        {...classNames("search-results", focused ? "focused" : "")}
      >
        {filteredResults.length ? (
          filteredResults.map((result) => (
            <li
              className="result-row"
              key={result.id}
              data-id={result.id}
              onClick={handleSelect}
            >
              <SquareAvatar
                avatar={`${appURLs.CDN_PLAIN}/${result.imageUri}`}
                title={""}
              />
              <div className="flex between align-center w-full">
                <span>{t(...result.label)}</span>
                <h5 className="type-callout">
                  {formatPaldeckId(result.paldeckId, result.paldeckIdSuffix)}
                </h5>
              </div>
            </li>
          ))
        ) : (
          <li className="result-row">
            <span className="type-callout pad-2">
              {t("common:noResults", "No Results")}
            </span>
          </li>
        )}
      </ul>
    </div>
  );
};

const PalworldBreedingCalculator = () => {
  const { t } = useTranslation();
  const { pals } = useWikiData();

  const title = IS_APP
    ? t("palworld:pals.breedingCalculator", "Breeding Calculator")
    : t(
        "palworld:pals.breedingCalculator.seoWeb",
        "Palworld Breeding Combos and Calculator w/ Passives & Chances",
      );

  if (!pals)
    return (
      <Container className="flex column gap-sp-4">
        <PageHeader
          title={title}
          className={cssPageHeader()}
          aside={
            <Button disabled emphasis="medium">
              {t("common:clearAll", "Clear All")}
            </Button>
          }
        />
        <div className="flex top-bar gap-sp-2 column">
          <section className={cssBreedingBar()}>
            <Card loading style={{ height: "192px" }} />
            <Card loading style={{ height: "192px" }} />
            <div className="equals">
              <EqualIcon />
            </div>
            <Card loading style={{ height: "192px" }} />
          </section>
        </div>
        <h3 className="result-count">
          {t(
            "palworld:breeding.resultsAll",
            "Showing {{results}} of 18,750 combinations",
            { results: "----" },
          )}
        </h3>
        <section className={cssPalsTable()}>
          <StyledFilterBar>
            <Select
              disabled
              selected={""}
              options={[
                {
                  value: "",
                  text: ["palworld:filters.allElements", "All Elements"],
                },
              ]}
              onChange={() => {}}
            />
            <Select
              disabled
              selected={""}
              options={[
                {
                  value: "",
                  text: ["palworld:filters.allWorkSkills", "All Work Skills"],
                },
              ]}
              onChange={() => {}}
            />
            <Select
              disabled
              selected={""}
              options={[
                {
                  value: "",
                  text: [
                    "palworld:filters.allPartnerSkills",
                    "All Partner Skills",
                  ],
                },
              ]}
              onChange={() => {}}
            />
          </StyledFilterBar>
          <DataTable
            loadingRows={25}
            sortable
            sortCol={2}
            sortDir={SORT_ASC}
            cols={[]}
            rows={null}
          />
        </section>
      </Container>
    );

  return <PalworldBreedingCalculatorImpl title={title} pals={pals} />;
};

export function meta() {
  return {
    title: [
      "palworld:meta.breedingCalculator.title",
      "Palworld Breeding Combos and Calculator w/ Passives & Chances",
    ],
    description: [
      "palworld:meta.breedingCalculator.description",
      "Find the best breeding combinations for your Palworld Pals. Use the calculator to find the best parents and child for your desired Pal. Includes passives and chances.",
    ],
  };
}

export default PalworldBreedingCalculator;
